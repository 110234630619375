import React from "react"
import Food from "../components/Food"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Food heading="Tasty Food Selection"/>
  </Layout>
)

export default IndexPage
